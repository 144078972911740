/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.img-logo{
    max-height:7%;
    max-width: 7%;
}

.bg-nsp {
  background-color: #a80000;
}

.centered {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.pointer{
    cursor: pointer;
}

.debug-border {
  border: solid;
  border-color: red;
}

.bryce-nsp-logo {
  background-image: url('assets/BryceLogoV7.png');
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0.1;
}

.v-small-image{
    max-height: 30px;
    max-width: 30px;
}
